@tailwind base;
@tailwind components;
@tailwind utilities;

@import 'react-datepicker/dist/react-datepicker.css';
@import 'react-toastify/dist/ReactToastify.min.css';

:root {
    --toastify-color-success: rgb(58, 167, 109);
    --toastify-color-warning: rgb(255, 192, 67);
    --toastify-color-error: rgb(212, 67, 51);
    --toastify-color-info: #3498db;
    --toastify-color-light: #3498db;
    --toastify-text-color-light: white;
    --toastify-color-progress-info: var(--toastify-color-info);
    --toastify-color-progress-success: var(--toastify-color-success);
    --toastify-color-progress-warning: var(--toastify-color-warning);
    --toastify-color-progress-error: var(--toastify-color-error);
    --toastify-toast-width: auto;
}

html,
body,
#root {
    height: 100%;
    width: 100%;
    padding: 0;
    margin: 0;
    border: none;
}

.react-datepicker__day.react-datepicker__day--keyboard-selected {
    background: none;
}

.react-datepicker__time-container
    .react-datepicker__time
    .react-datepicker__time-box
    ul.react-datepicker__time-list
    li.react-datepicker__time-list-item:hover {
    background-color: #f3f4f6;
}

.react-datepicker .react-datepicker__day--disabled {
    color: lightgray;
    cursor: not-allowed;
}

.dark .react-datepicker .react-datepicker__day--disabled {
    color: #333333;
    cursor: not-allowed;
}

.dark .react-datepicker .react-datepicker__header {
    background-color: #888888;
}

.dark
    .react-datepicker__time-container
    .react-datepicker__time
    .react-datepicker__time-box
    ul.react-datepicker__time-list
    li.react-datepicker__time-list-item:hover {
    background-color: rgb(84 84 84 / var(--tw-bg-opacity));
}

.dark
    .react-datepicker__time-container
    .react-datepicker__time
    .react-datepicker__time-box
    ul.react-datepicker__time-list
    li.react-datepicker__time-list-item--selected {
    background-color: rgb(23 66 145 / var(--tw-bg-opacity));
}

.react-datepicker__time-container
    .react-datepicker__time
    .react-datepicker__time-box
    ul.react-datepicker__time-list
    li.react-datepicker__time-list-item--selected {
    background-color: rgb(39 110 241 / var(--tw-bg-opacity));
}

.dark
    .react-datepicker__time-container
    .react-datepicker__time
    .react-datepicker__time-box
    ul.react-datepicker__time-list
    li.react-datepicker__time-list-item--selected:hover {
    background-color: rgb(16 44 96 / var(--tw-bg-opacity));
}

.react-datepicker__time-container
    .react-datepicker__time
    .react-datepicker__time-box
    ul.react-datepicker__time-list
    li.react-datepicker__time-list-item--selected:hover {
    background-color: rgb(30 84 183 / var(--tw-bg-opacity));
}
.react-datepicker__input-container {
    display: block;
}
svg path {
    transition: 0.3s;
}
.max-w-full > .truncate,
.max-w-full > fieldset > legend > .truncate {
    white-space: normal;
}
.flex.items-center.space-x-4.mt-1\.5.mb-2 {
    flex-wrap: wrap;
    margin-left: -1rem;
}
.flex.items-center.space-x-4.mt-1\.5.mb-2 > .flex:first-child {
    margin-left: 1rem;
}
.Toastify > .Toastify__toast-container {
    max-width: 800px;
}
.iframe-container iframe {
    border: none;
    width: 100%;
}
